/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
} */

/* body,
html {
    height: 50%;
    width: 100%;
    overflow: hidden;
} */

.chat-container {
    display: flex;
    flex-direction: column;
    height: 85vh;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 15px;
}

.chat-header {
    background-color: #4a6fa5;
    color: white;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 15px;
    display: flex;
}

.user-message {
    justify-content: flex-end;
}

.bot-message {
    justify-content: flex-start;
}

.message-content {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 18px;
    position: relative;
}

.user-message .message-content {
    background-color: #4a6fa5;
    color: white;
    border-bottom-right-radius: 5px;
}

.bot-message .message-content {
    background-color: #e5e5ea;
    color: black;
    border-bottom-left-radius: 5px;
}

.timestamp {
    font-size: 0.7rem;
    opacity: 0.7;
    margin-top: 5px;
    display: block;
}

.user-message .timestamp {
    text-align: right;
}

.chat-input-container {
    display: flex;
    padding: 15px;
    background-color: white;
    border-top: 1px solid #e0e0e0;
}

.chat-input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;
    font-size: 1rem;
}

.send-button {
    background-color: #4a6fa5;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 0 20px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 1rem;
}

.send-button:hover {
    background-color: #3a5a8f;
}

/* For WebKit browsers (Chrome, Safari) */
.messages-container::-webkit-scrollbar {
    width: 6px;
}

.messages-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}