.table-wrapper {
    width: 100%;
    overflow-x: auto;
    position: relative;
    border: 1px solid #dee2e6;
}

/* Scrollable table */
.table-container {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}

/* Table styling */
.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    white-space: nowrap;
}

/* Table Headers */
th,
td {
    outline: 1px solid #dee2e6;
    text-align: left;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Fix first three columns */
th.sticky-col,
td.sticky-col {
    position: sticky;
    left: 0;
    z-index: 2;
    
    /* width: 250px !important; */
    /* outline: 1px solid #dee2e6; */
    /* Ensures borders don’t disappear */
}

/* Fix individual sticky column positions */
th.sticky-col:nth-child(1),
td.sticky-col:nth-child(1) {
    left: 0px;
}

th.sticky-col:nth-child(2),
td.sticky-col:nth-child(2) {
    left: 100px;
}

th.sticky-col:nth-child(3),
td.sticky-col:nth-child(3) {
    left: 160px;
}

th.sticky-col:nth-child(4),
td.sticky-col:nth-child(4) {
    left: 360px;
}

/* Add shadow effect for better separation */
th.sticky-col {
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
}

/* Fix sorting icon alignment */
th {
    position: relative;
    cursor: pointer;
}

th svg {
    margin-left: 5px;
    vertical-align: middle;
}

/* Fix pagination not scrolling */
.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: white;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    border-top: 1px solid #dee2e6;
}